@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: z-index(header);
	width: 100%;
	padding-top: 0.4rem;
	padding-bottom: 0.5rem;
	@include transition(all, 0.3, ease-in, 0);
	background: $white;
	@include header-up {
		padding-top: 1.2rem;
		padding-bottom: 1.4rem;
	}
	@include header-down {
		height: $header-hight_sp;
	}	
	&.is-absolute {
		position: absolute;
	}
	&.js-fixed {
		box-shadow: 0 0 10px rgba($black, 0.1);
		@include header-up {
			padding-top: 0.8rem;
			padding-bottom: 0.9rem;
		}
	}
}

// ロゴ　最大幅
$h_logo_w: 250; //px
.h- {
	&inner {
		position: relative;
		@include m-a;
		padding: 0 map-get($contmargin, sp);
		@include media-breakpoint-up(sm) {
			padding: 0 2%;
		}
		@include media-breakpoint-up(xl) {
			padding: 0 map-get($contmargin, pc);
		}
	}
	&flex {
		@include flex-between;
		align-items: center;
	}
	&left {
		width: 70%;
		max-width: 220px;
		@include header-up {
			width: 16%;
			max-width: #{$h_logo_w}px;
		}
		@include media-breakpoint-up(xl) {
			width: #{$h_logo_w}px;
		}
		@include media-breakpoint-up(full) {
      max-width: floor-decimal(calc($h_logo_w / 1920), 4) * 100vw;
    }
	}
	&logo {
		letter-spacing: 0;
		text-align: left;
		@include header-down {
			margin-top: 0.5rem;
		}
	}
	&right {
		@include flex-end;
		align-items: center;
		@include header-down {
			display: none;
		}
	}
	&tel {
		margin-left: clamp(0.5em,1.25vw,1em);
		@include f-family(font02);
		font-size: 1.35em;
		color: $other_c3;
		@include l-sp(0);
		@include f-w(700);
		@include media-breakpoint-up(xl) {
			font-size: 1.5em;
		}
		i {
			font-size: 0.75em;
			color: $other_c1;
			@include f-w(100);
			margin-right: 0.2em;
		}
		a {
			color: $txt_c;
		}
		.is-change & {
			color: $white;
			i {
				color: $white;
			}
			a {
				color: $white;
			}
		}
		.header.js-fixed & {
			color: $txt_c;
			i {
				color: $other_c1;
			}
			a {
				color: $txt_c;
			}
		}
	}
}
