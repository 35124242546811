@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
.footer {
	background: $white;
	overflow: hidden;
}
.f- {
	&inner {
		color: $white;
		background: $other_c2;
		@include media-breakpoint-up(lg) {
			padding-bottom: calc(env(safe-area-inset-bottom) + clamp(4rem, 6vw, 6rem));
		}
		@include media-breakpoint-down(md) {
			padding-bottom: calc(env(safe-area-inset-bottom) + clamp(6rem, 7vw, 7rem));
		}
		.l-cont {
			position: relative;
		}
	}
	&flex {
		display: flex;
		margin-bottom: clamp(2rem, 4vw, 4rem);
		@include media-breakpoint-up(lg) {
			padding-top: clamp(2rem, 4vw, 4rem);
			justify-content: space-between;
			column-gap: 10%;
		}
		@include media-breakpoint-down(md) {
			padding-top: clamp(3rem, 6vw, 6rem);
			flex-direction: column-reverse;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&info {
		@include media-breakpoint-up(lg) {
			padding-right: 5%;
		}
		&__tit {
			margin-bottom: 1em;
			max-width: 190px;
			@include media-breakpoint-down(sm) {
				width: 70%;
			}
			img {
				width: 100%;
			}
		}
		address {
			line-height: 1.5;
			font-size: 0.875em;
		}
	}
	&nav {
		padding: clamp(2.5rem, 4vw, 4rem) 0;
		border-bottom: 1px solid $white;
		@include media-breakpoint-down(md) {
			display: none;
		}
		&__items {
			@include media-breakpoint-up(lg) {
				@include flex-wrap;
				gap: 1em 2em;
			}
			&:not(:last-child) {
				padding-right: 3rem;
			}
			li {
				line-height: 1.5;
			}
			a {
				color: $white;
				font-size: 0.94em;
				position: relative;
				@include dec-none;
				padding-left: 1.75em;
				@include f-w(500);
				&::before {
					content: "";
					position: absolute;
					top: 0.75em;
					left: 0;
					width: 12px;
					height: 1px;
					vertical-align: middle;
					background-color: $white;
				}
				&:hover {
					@include dec-line;
				}
			}
		}
	}
	&copy {
		font-size: 0.875em;
		color: $white;
	}
}

/* pagetop
--------------------------------------------------------------------*/
.pagetop {
	position: absolute;
	z-index: z-index(pagetop);
	bottom: -2rem;
	right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		right: map-get($contmargin, pc);
	}
	& a {
		height: 48px;
		display: block;
		@include dec-none;
		@include f-size(48);
		@include transition;
		color: $white;
		&:hover {
			color: $other_c1;
		}
		@include media-breakpoint-up(md) {
			height: 64px;
			@include f-size(64);
		}
	}
	i {
		vertical-align: top;
	}
}
