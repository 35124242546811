@use "../_mixins/break-points" as *;
@use "../base" as *;

//@import "../_nav/sidr";
.pc-nav {
  @include header-down(lg) {
    display: none;
  }
}
.pc-nav__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: clamp(0.3em,1vw,1em);
  @include media-breakpoint-up(xl) {
    column-gap: clamp(0.5em,1.25vw,1.25em);
  }
  & > li {
    & > a {
      vertical-align: middle;
      color: $txt_c;
      @include transition;
      @include dec-none;
      position: relative;
      font-size: 1em;
      @include f-w(500);
      padding-bottom: 7px;
      @include media-breakpoint-up(lg) {
        @include f-s_lg(0.8,2);
      }
      @include media-breakpoint-up(xl) {
        font-size: 1em;
      }
      &::after {
        content: "";
        background-color: $main_c;
        width: 0%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        @include transition;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
    .is-change & > a {
      color: $white;
      &::after {
        background-color: $white;
      }
    }
    .header.js-fixed & > a {
      color: $txt_c;
      &::after {
        background-color: $main_c;
      }
      &:hover {
        color: $main_c;
      }
    }
  }
  //お問い合わせボタン
  & .contact {
    a {
      display: inline-block;
      background: $main_c;
      color: $white !important;
      padding: 0.5em 1.25em 0.5em 1.5em;
      text-align: center;
      font-size: 0.8em;
      @include radius(999px);
      @include icon(mail, before);
      @include media-breakpoint-up(xl) {
        font-size: 0.94em;
      }
      &::before {
        margin-right: 0.2em;
        margin-bottom: 0.3em;
        vertical-align: baseline;
        font-size: 1em;
      }
      &::after {
        display: none;
      }
      &:hover {
        background-color: $hover_c;
      }
    }
  }
  //会員限定
  & .members {
    a {
      display: inline-block;
      background: $other_c1;
      color: $white !important;
      padding: 0.5em 1.25em 0.5em 1.5em;
      text-align: center;
      font-size: 0.8em;
      @include radius(999px);
      @include icon(lock, before);
      @include media-breakpoint-up(xl) {
        font-size: 0.94em;
      }
      &::before {
        margin-right: 0.2em;
        margin-bottom: 0.3em;
        vertical-align: baseline;
        font-size: 1em;
      }
      &::after {
        display: none;
      }
      &:hover {
        background-color: $hover_brown;
      }
    }
  }
}

//二階層メニュー
// サブメニュー　幅
$pc_submenu_w: 240;//px
.pc-lv2 {
  position: relative;
  &:hover {
    & .sub-menu {
      top: 3em;
      visibility: visible;
      opacity: 1;
    }
  }
  & .sub-menu {
    position: absolute;
    @include transition;
    top: 2.625em;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    left: 0;
    z-index: 10;
    width: #{$pc_submenu_w}px;
    @include radius(6px);
    @include flex-column;
    @include media-breakpoint-up(full) {
      width: floor-decimal(calc($pc_submenu_w / 1920), 4) * 100vw;
    }
    li {
      width: auto;
    }
    & a {
      display: block;
      @include dec-none;
      font-size: 0.825em;
      padding: 0.75em 1.25em;
      color: $white;
      line-height: 1.4;
      position: relative;
      background: $other_c3;
      @include transition;
      &:hover {
        background: $main_c;
        color: $white;
      }
    }
  }
}

/*--------------------------------------------------------------------/
		modal
/--------------------------------------------------------------------*/
.body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar02 {
  0% {
    transform: translateX(-12px);
    opacity: 0;
  }
  75% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes menu-bar03 {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
@keyframes active-menu-bar02 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    transform: translateX(12px);
    opacity: 0;
  }
  100% {
    transform: translateX(12px);
    opacity: 0;
  }
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}

/* スマホメニュー */
.close-btn {
  @include header-down {
    a {
      @include dec-none;
    }
  }
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: z-index(hamburger);
  @include header-up {
    display: none;
  }
  .open &-btn {
    background: transparent;
  }
  &-btn {
    position: relative;
    width: 64px;
    height: 64px;
    padding: 8px;
    cursor: pointer;
    display: block;
    transition: all 0.4s;
    box-sizing: border-box;
    & span {
      display: inline-block;
      transition: all 0.4s;
      box-sizing: border-box;
      position: absolute;
      left: 12px;
      width: 40px;
      height: 1px;
      background-color: $txt_c;
      .hamburger.is-change & {
        background-color: $white;
      }
      .hamburger.js-fixed & {
        background-color: $txt_c;
      }
      &:nth-of-type(1) {
        top: 21px;
        animation: menu-bar01 0.5s forwards;
      }
      &:nth-of-type(2) {
        top: 31px;
        animation: menu-bar02 0.5s forwards;
      }
      &:nth-of-type(3) {
        top: 41px;
        animation: menu-bar03 0.5s forwards;
      }
    }
    &.is-active {
      & span {
        background-color: $other_c3 !important;
        &:nth-of-type(1) {
          animation: active-menu-bar01 0.5s forwards;
        }
        &:nth-child(2) {
          animation: active-menu-bar02 0.5s forwards;
        }
        &:nth-of-type(3) {
          animation: active-menu-bar03 0.5s forwards;
        }
      }
    }
  }
}

.sp-nav {
  @include header-up {
    display: none;
  }
  @include header-down {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg_c1;
    visibility: hidden;
    z-index: z-index(sp-nav);
    body.open & {
      cursor: "", auto;
      @include transition(0.5);
      visibility: visible;
      opacity: 1;
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding-bottom: clamp(4rem,6vw,6rem);
  }
  &__head {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    background: $bg_c1;
    padding-top: 0.4rem;
    padding-bottom: 0.5rem;
    width: 100%;
    z-index: z-index(module,part01);
  }
  &__tit {
    width: 70%;
    max-width: 220px;
    margin-top: 0.5rem;
    margin-left: map-get($contmargin,sp);
    @include media-breakpoint-up(sm) {
      margin-left: 2%;
    }
  }
  &__cont {
    padding-top: 5rem;
    @include media-breakpoint-up(md) {
      width: 80%;
      @include m-a;
    }
  }
}

// sp-nav__list
.sp-nav__list {
  & > li {
    width: 100%;
    margin: 0 auto;
    margin-top: -15px;
    text-align: left;
    opacity: 0;
    border-bottom: 1px solid $other_c1;
    line-height: 1.5em;
    width: 100%;
    & a {
      display: block;
      color: $other_c3;
      text-decoration: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 1.5em 5%;
      position: relative;
      @include f-w(500);
      @include media-breakpoint-up(md) {
        padding: 1.5em 0;
      }
    }
  }
}

//二階層メニュー
.sp-lv2 {
  position: relative;
  width: 100%;
  & > a[href="javascript:void(0);"] {
    &::before,
    &::after {
      font-style: normal;
      position: absolute;
      top: 50%;
      right: 1em;
      display: block;
      width: 14px;
      height: 1px;
      content: "";
      @include transition;
      background-color: $other_c2;
    }
    &::before {
      transform: rotate(0deg);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.is-active {
      &::before {
        display: none;
      }
      &::after {
        top: 50%;
        transform: rotate(180deg);
      }
    }
  }
  & .sub-menu {
    display: none;
    & li {
      border-top: 1px solid $other_c1;
      background: $o-white;
      a {
        padding-left: calc(5% + 1.4em);
        position: relative;
        &::before {
          position: absolute;
          content: "";
          top: 2.25em;
          left: 5%;
          width: 10px;
          height: 1px;
          background: $other_c2;
        }
      }
    }
  }
}

body.open .sp-nav__list > li {
  opacity: 1;
  margin-top: 0;
  @for $num from 1 through 10 {
    &:nth-child(#{$num}) {
      transition: 0.5s ease (0.2s + calc($num / 10));
    }
  }
}

// 電話ボタン
.sp-nav__tel {
  margin-top: 1.5rem;
  padding: 1em 1.25em;
  opacity: 0;
  background: $white;
  border-radius: clamp(8px, 1vw, 16px);
  @include media-breakpoint-down(sm) {
    width: 80%;
    @include m-a;
  }
  @include media-breakpoint-down(xxs) {
    width: 90%;
  }
  body.open & {
    opacity: 1;
    @include transition(all, 0.5, ease, 1.4);
    transition: 0.5s ease 1.3s;
  }
  &__time {
    text-align: center;
    margin-bottom: 0.3em;
    @include f-w(500);
  }
  &__num {
    @include line-h(1.2);
    @include f-w(500);
    text-align: center;
    @include f-s_xxs(1.5, 4);
    @include media-breakpoint-up(xl) {
      @include f-size(28);
    }
    span {
      display: block;
      color: $txt_c;
      @include f-family(font02);
      @include icon(phone, before);
      &::before {
        font-size: 0.75em;
        margin-right: 0.2rem;
        padding-bottom: 0.5rem;
        @include f-w(400);
        color: $other_c1;
      }
    }
    .tel-link a {
      color: $txt_c;
    }
  }
}

// クローズボタン
.sp-nav {
  &__close {
    margin-top: 2rem;
    opacity: 0;
    @include m-a;
    width: 80%;
    @include media-breakpoint-down(xxs) {
      width: 90%;
    }
    body.open & {
      opacity: 1;
      @include transition(all, 0.5, ease, 1.5);
    }
    a {
      @include line-h(1.2);
      @include f-w(500);
      text-align: center;
      border: 1px solid $white;
      @include dec-none;
      width: 100%;
      display: block;
      color: $white;
      height: 60px;
      line-height: 60px;
      @include f-family(font01);
      background: $other_c3;
      @include radius(999px);
      &::before {
        content: "×";
        margin-right: 0.3rem;
        @include f-family(font01);
      }
    }
  }
}
