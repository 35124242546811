@use "../_mixins/break-points" as *;
@use "../base" as *;

/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */
#wrapper {
	background: $white;
}

/* main */
.main {
	@include clearfix;
	background: $white;
	img {
		display: block;
	}
}

/* contents */
.l-cont_lg {
	max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
}

.l-cont {
	max-width: map-get($container-max-widths, xl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
		max-width: floor-decimal(calc(map-get($grid-num, xl) / map-get($grid-num, full)), 4) * 100vw +
			map-get($contmargin, full) * 2; //メインコンテンツサイズ
	}
}
.l-cont_xxl {
	max-width: map-get($container-max-widths, xxl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
		max-width: floor-decimal(calc(map-get($grid-num, xxl) / map-get($grid-num, full)), 4) * 100vw +
			map-get($contmargin, full) * 2; //メインコンテンツサイズ
	}
}
.l-cont_full {
	max-width: 100%; //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
	}
}

/* contents-box */
// Layout box
.l-box {
	width: 100%;
	z-index: 1;
	position: relative;
	&:not(:last-of-type) {
		margin-bottom: 8rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 10rem;
		}
	}
}

// common box
.c-box1 {
	a {
		@include dec-none;
		color: $txt_c;
		height: 100%;
		display: block;
		background: $white;
		@include transition;
	}
	&__inner {
		@include flex-c-reverse;
	}
	&__img {
		&__inner {
			@include aspect-img;
			@include radius_xs;
			overflow: hidden;
			margin: 0;
		}
	}
	&__txt {
		background: $white;
		padding: 1em 4% 1.5em;
		.txt {
			@include media-breakpoint-up(sm) {
				font-size: 0.9em;
			}
		}
	}
	&__category {
		@include flex-wrap;
		column-gap: 4%;
		row-gap: 0.4em;
		margin-bottom: 0.3em;
		& > * {
			min-width: 48%;
		}
		.category-item {
			width: 100%;
			@include l-sp(0);
			padding: 0.4em .75em;
			@include media-breakpoint-up(md) {
				font-size: 0.8em;
			}
		}
	}
	&__tit {
		font-size: 1.14em;
		@include f-w(700);
		color: $other_c2;
		margin-bottom: 0.5em;
	}
}

// 一覧詳細ページ　調整
.common-flex__list {
	&.is-col2 {
		.c-box1 {
			&__date {
				@include media-breakpoint-up(lg) {
					font-size: 1.25em;
				}
			}
			&__category {
				@include media-breakpoint-up(lg) {
					font-size: 1.25em;
				}
			}
			&__tit {
				@include media-breakpoint-up(lg) {
					@include f-size(22);
				}
			}
		}
	}
}

.c-box2 {
	position: relative;
	background: $white;
	text-align: left;
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		@include transition;
		color: $txt_c;
		overflow: hidden;
		padding: 1.5em 0;
		border-bottom: 1px solid $l-gray;
		@include media-breakpoint-up(md) {
			padding: 2em 0;
		}
		&::after {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 0;
			height: 1px;
			background: $hover_c;
			@include transition;
			z-index: z-index(module,part02);
		}
		&:hover {
			&::after {
				width: 100%;
			}
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;

		@include media-breakpoint-up(sm) {
			width: 35.91%;
		}
		@include media-breakpoint-up(xl) {
			width: 300px;
		}
		&__inner {
			@include aspect-img;
			@include radius_s;
			margin: 0;
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(100% - 300px);
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
		&__inner {
			width: 100%;
		}
	}
	&__date {
		@include f-family(font01);
		@include f-w(500);
		@include l-sp(0);
		color: $main_c;
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		row-gap: 0.2em;
		.category-item {
			font-size: 0.7em;
			@include media-breakpoint-up(lg) {
				font-size: 0.85em;
			}
		}
	}
	&__tit {
		@include f-s_xxs(1, 3);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(sm) {
			@include f-s_sm(0.9, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
	}
}

// works-box
.works-box {
	a {
		@include dec-none;
		color: $txt_c;
		height: 100%;
		display: block;
		background: $white;
		@include transition;
	}
	&__img {
		overflow: hidden;
		&__inner {
			@include aspect-img;
			@include radius_xs;
			overflow: hidden;
		}
	}
	&__txt {
		padding: 1em 4% 1.5em;
		&__tit {
			font-size: 1.14em;
			@include f-w(700);
			color: $other_c2;
			margin-bottom: 0.5em;
		}
		&__txt {
			@include media-breakpoint-up(sm) {
				font-size: 0.9em;
			}
		}
	}
	&__category {
		@include flex-wrap;
		column-gap: 4%;
		row-gap: 0.4em;
		& > * {
			min-width: 48%;
		}
		.category-item {
			width: 100%;
			@include l-sp(0);
			padding: 0.4em .75em;
			@include media-breakpoint-up(md) {
				font-size: 0.8em;
			}
		}
	}
}

// 一覧詳細ページ　調整
.common-flex__list {
	&.is-col2 {
		.works-box {
			&__txt {
				&__tit {
					@include media-breakpoint-up(lg) {
						@include f-size(20);
					}
				}
			}
		}
	}
}
