@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
/* common-contact
---------------------------------------------------------------------*/
.common-contact {
	padding: clamp(2rem, 6vw, 6rem) 0;
	overflow: hidden;
	position: relative;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-top-right-radius: clamp(36px, 10vw, 150px);
		border-bottom-right-radius: clamp(36px, 10vw, 150px);
		@include bg_filter($white, 0.7, before);
		overflow: hidden;
		@include media-breakpoint-up(xl) {
			width: 86.45%;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		width: 100%;
		display: flex;
		@include media-breakpoint-up(lg) {
			align-items: center;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	&__tit {
		margin-bottom: clamp(2rem, 4vw, 4rem);
		@include media-breakpoint-up(lg) {
			width: 35%;
		}
		em {
			@include f-family(font02);
			@include f-w(400);
			display: inline-block;
			@include line-h(1);
			font-style: normal;
			@include f-s_xxs(2, 36);
			padding-right: 0.4em;
			color: $main_c;
			display: block;
			margin-bottom: 0.1em;
			@include media-breakpoint-up(xl) {
				@include f-size(72);
			}
		}
		b {
			@include f-s_xxs(1, 14);
			@include f-w(500);
			display: block;
			color: $other_c2;
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}
}

.common-contact__txt {
	background: $white;
	position: relative;
	@include media-breakpoint-up(md) {
		padding: clamp(2.5em, 3.5vw, 4rem) 0 clamp(2.5em, 3.5vw, 4rem) clamp(3.5em, 7vw, 6rem);
		border-top-left-radius: 999px;
		border-bottom-left-radius: 999px;
	}
	@include media-breakpoint-up(lg) {
		width: 65%;
	}
	@include media-breakpoint-down(sm) {
		border-top-left-radius: clamp(6px,2vw,30px);
		border-bottom-left-radius: clamp(6px,2vw,30px);
		padding: clamp(2em, 3.5vw, 4rem) 0 clamp(2em, 3.5vw, 4rem) clamp(1.5em, 4vw, 3rem);
	}
	&::after {
		position: absolute;
		top: 0;
		left: calc(100% - 1px);
		content: "";
		width: 50vw;
		height: 100%;
		background: $white;
		z-index: -1;
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			width: 100%;
			column-gap: 1.5em;
		}
		@include media-breakpoint-down(sm) {
			width: 60%;
			row-gap: 1em;
			flex-direction: column;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			align-items: center;
		}
	}
	&__txt {
		color: $txt_c;
		margin-bottom: clamp(1em, 2vw, 2rem);
		position: relative;
		.txt {
			margin-bottom: clamp(2rem, 3vw, 3rem);
			@include media-breakpoint-down(md) {
				margin-bottom: clamp(1rem, 2vw, 2rem);
			}
		}
	}
}

.common-contact__tel {
	padding-left: 2em;
	@include media-breakpoint-up(md) {
		flex-shrink: 0;
	}
	&__time {
		margin-bottom: 0.2em;
		@include f-w(500);
		@include media-breakpoint-down(lg) {
			font-size: 0.94em;
		}
	}
	&__num {
		@include f-family(font02);
		position: relative;
		@include icon(phone, before);
		@include f-s_xxs(2, 12);
		@include line-h(1);
		color: $other_c3;
		@include media-breakpoint-up(md) {
			@include f-s_md(2, 12);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.5, 16);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(42);
		}
		&::before {
			position: absolute;
			font-size: 0.6875em;
			bottom: 0;
			right: calc(100% + 0.2em);
		}
	}
	a {
		color: $txt_c !important;
	}
}

.common-contact__btn {
	@include media-breakpoint-up(md) {
		flex-basis: 48%;
		flex-shrink: 1;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	a {
		width: 100%;
		max-width: 400px;
		display: flex;
		@include transition;
		@include dec-none;
		background: $main_c;
		color: $white;
		justify-content: center;
		align-items: center;
		@include radius(999px);
		position: relative;
		padding: 1em 1.5em;
		&:hover {
			background: $hover_c;
		}
	}
	&__txt {
		@include f-family(font02);
		@include f-s_xxs(1.14, 6);
		@include icon(arrow1_right, after);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.14, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::after {
			position: absolute;
			top: 50%;
			right: 0.5em;
			translate: 0 -50%;
		}
	}
}
