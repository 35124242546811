@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?rieu5v');
  src:  url('../fonts/icomoon.eot?rieu5v#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?rieu5v') format('truetype'),
    url('../fonts/icomoon.woff?rieu5v') format('woff'),
    url('../fonts/icomoon.svg?rieu5v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow1_top:before {
  content: "\e316";
}
.icon-arrow1_right:before {
  content: "\e315";
}
.icon-arrow1_bottom:before {
  content: "\e313";
}
.icon-arrow1_left:before {
  content: "\e314";
}
.icon-arrow2_top:before {
  content: "\e907";
}
.icon-arrow2_right:before {
  content: "\e901";
}
.icon-arrow2_bottom:before {
  content: "\e906";
}
.icon-arrow2_left:before {
  content: "\e902";
}
.icon-direction_arrow_top:before {
  content: "\e913";
}
.icon-direction_arrow_right:before {
  content: "\e914";
}
.icon-direction_arrow_bottom:before {
  content: "\e915";
}
.icon-direction_arrow_left:before {
  content: "\e916";
}
.icon-home:before {
  content: "\e90c";
}
.icon-phone:before {
  content: "\e909";
}
.icon-mail:before {
  content: "\e911";
}
.icon-link:before {
  content: "\e900";
}
.icon-lock:before {
  content: "\e90f";
}
.icon-calender:before {
  content: "\e903";
}
.icon-pdf:before {
  content: "\e904";
}
.icon-tag:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e908";
}
.icon-search-plus:before {
  content: "\e90a";
}
.icon-facebook1:before {
  content: "\e90b";
}
.icon-facebook2:before {
  content: "\e912";
}
.icon-x:before {
  content: "\e90d";
}
.icon-instagram:before {
  content: "\e90e";
}
.icon-line:before {
  content: "\e910";
}
.icon-youtube:before {
  content: "\e917";
}
.icon-download:before {
  content: "\e918";
}