@use "../base" as *;

/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/

html{
	@include f-base;
}
body {
	@include l-sp;
	font-size: 1rem;
	@include set_font-family;
	@include line-h;
	@include f-w(500);
	word-wrap: break-word;
	color: $txt_c;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	@include scroll-bar;
	background: $bg_loader; //ロードするときのタイムラグ時の画面の色
	@include media-breakpoint-up(full) {
		font-size: floor-decimal(calc(map-get($media-rem,pc) / map-get($grid-num,full)), 4) * 100vw;
	}
}

::-webkit-input-placeholder{
	@include set_font-family;
}
:-moz-placeholder {
	@include set_font-family;
}

// selection
::selection {
	background: $main_c;
	color: $white;
}

/*
section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}
*/

/* heading 
----------------------------------------------------------------*/
h1,h2,h3,h4,h5,h6{
	@include line-h(1.4);
}

/* forn parts
----------------------------------------------------------------*/
input {
	font-size: 0.94em;
}

select {
	font-size: 0.94em;
}

textarea {
	font-size: 0.94em;
}

/* a,img
----------------------------------------------------------------*/
a{
	color:$link_c;
	vertical-align: top;
	&:link,&:visited {
		text-decoration: underline;
	}
	&:hover,&:active {
		text-decoration: none;
	}
	img {
		display:block;
		width: 100%;
	}
	&.hover {
		img {
			@include transition;
			&:hover {
				opacity: .8;
			}
		}
	} 
}

img {
	max-width: 100%;
	display: block;
}


/* tel-link */
.tel-link {
	a {
		@include dec-none;
	}
}

/* scroll-position */
[id*="link-"],
.scroll-position {
	padding-top:calc(1em + $header-hight_sp);
	margin-top:calc(-1em - $header-hight_sp);
	@include media-breakpoint-up(xl) {
		padding-top:6rem;
		margin-top:-6rem;
	}
}

/* scroll-bar */
.scroll-bar {
	@include scroll-bar;
}
