@use "../_mixins/break-points" as *;
@use "../base" as *;

/*----------------------------------------------------------------
	component(common)
----------------------------------------------------------------*/

/* heading
/--------------------------------------------------------------------*/
.cont-tit {
	margin-bottom: clamp(2rem, 3vw, 3rem);
	position: relative;
	b {
		@include f-family(font02);
		color: $other_c1;
		@include f-s_xxs(1.75, 36);
		@include f-w(400);
		position: relative;
		display: inline-block;
		@include line-h(1.2);
		@include l-sp(0.05em);
		padding-top: 0.3em;
		padding-right: 1.2em;
		margin-bottom: 0.2em;
		@include media-breakpoint-up(lg) {
			padding-right: 1.4em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(60);
		}
		&::after {
			position: absolute;
			top: 0;
			right: 0;
			content: "";
			width: clamp(24px, 4vw, 58px);
			height: clamp(30px, 4.51vw, 65px);
			background: url(../img/common/tit-light_brown.svg) center center/contain no-repeat;
			@include media-breakpoint-up(xl) {
				width: 58px;
				height: 65px;
			}
		}
	}
	small {
		@include f-family(font01);
		@include f-s_xxs(1, 12);
		color: $other_c2;
		display: block;
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
}

.heading-tit {
	@include f-family(font01);
	color: $other_c2;
	@include f-w(700);
	text-align: center;
	@include f-s_xxs(1.14, 14);
	@include l-sp(0);
	position: relative;
	padding-bottom: clamp(1.5rem, 2vw, 2rem);
	margin-bottom: clamp(2rem, 3vw, 3rem);
	@include media-breakpoint-up(xl) {
		@include f-size(32);
	}
	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		translate: -50% 0;
		background: radial-gradient(circle farthest-side, $other_c1, $other_c1 30%, transparent 30%, transparent);
		background-size: 16px;
		content: "";
		display: inline-block;
		height: 22px;
		width: 92px;
	}
}

.heading-tit2 {
	text-align: center;
	margin-bottom: clamp(2.5rem, 4vw, 4rem);
	@include f-family(font01);
	@include f-s_xxs(1.14, 14);
	color: $other_c1;
	display: block;
	@include f-w(700);
	position: relative;
	padding-top: 3em;
	@include media-breakpoint-up(xl) {
		@include f-size(36);
	}
	&::before {
		position: absolute;
		top: 0;
		left: calc(50% - 1.5em);
		content: "";
		width: 3em;
		height: 3em;
		background: url(../img/common/heart.svg) center center/contain no-repeat;
	}
}

.heading-tit3 {
	margin-bottom: clamp(2rem, 3vw, 3rem);
	@include f-w(700);
	color: $other_c2;
	@include f-s_xxs(1.14,10);
	text-align: center;
	@include media-breakpoint-up(xl) {
		@include f-size(28);
	}
}

/* teble用のタイトル */
.table-tit {
}

/* ol
/--------------------------------------------------------------------*/
.common-ol {
	margin-left: 5px;
	counter-reset: ol-num;
	& > li {
		line-height: 1.4;
		padding-left: 1.5em;
		text-indent: -1.5em;
		position: relative;
		&::before {
			counter-increment: ol-num;
			content: counter(ol-num) ".";
			padding-right: 0.5em;
			text-align: center;
		}
		&:not(:last-child) {
			margin-bottom: 0.7rem;
		}
	}
}

/* ul
/--------------------------------------------------------------------*/
.common-ul {
	text-align: left;
	&.is-main {
		li::before {
			color: $main_c;
		}
	}
	&.is-sub {
		li::before {
			color: $sub_c;
		}
	}
	&.is-brown {
		li::before {
			color: $other_c1;
		}
	}
	& li {
		line-height: 1.4;
		padding-left: 1.5rem;
		position: relative;
		&::before {
			content: "●";
			color: $gray;
			position: absolute;
			left: 0;
			top: 0.2em;
			font-size: 0.8em;
		}
		&:not(:last-child) {
			margin-bottom: .75em;
		}
	}
}

.common-ul2 {
	margin-left: 5px;
	text-align: left;
	& li {
		line-height: 1.4;
		padding-left: 1rem;
		position: relative;
		&::before {
			content: "・";
			position: absolute;
			color: $txt_c;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.3rem;
		}
	}
}

.caution-ul {
	margin-left: 5px;
	& li {
		line-height: 1.4;
		padding-left: 1.2rem;
		position: relative;
		color: $red;
		&::before {
			content: "※";
			position: absolute;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
	margin-left: 5px;
	margin-bottom: 2rem;
	& dt {
		line-height: 1.4;
		&:before {
			content: "●";
			padding-right: 0.3em;
		}
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}

.common-dl2 {
	margin-bottom: 1rem;
	& dt {
		line-height: 1.4;
		padding-bottom: 0.7rem;
		border-bottom: 1px solid $m-gray;
		margin-bottom: 0.7rem;
		font-size: 1em;
		@include f-w(500);
		font-size: 1.14em;
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

/* table
----------------------------------------------------------------*/
.common-table {
	width: 100%;
	margin-bottom: 2rem;
	& tr {
		border-bottom: 1px solid $m-gray;
		&:first-child {
			border-top: 1px solid $m-gray;
		}
	}
	& th,
	& td.column-1 {
		text-align: left;
		@include f-w(700);
		padding: 1.25em 0;
		@include media-breakpoint-up(lg) {
			width: 25%;
			min-width: 180px;
		}
		@include media-breakpoint-up(xl) {
			width: 225px;
		}
		@include media-breakpoint-down(md) {
			display: block;
			padding-bottom: 0;
		}
	}
	& td.column-2 {
		padding: 1.25em 1.25rem 1.25em 0.5rem;
		@include media-breakpoint-down(md) {
			display: block;
			padding-top: 0;
			padding-left: 0;
		}
	}
}

.common-table2 {
	width: 100%;
	font-size: 0.94em;
	margin-bottom: 2rem;
	border-left: 1px solid $m-gray;
	border-right: 1px solid $m-gray;
	& tr {
		border-bottom: 1px solid $m-gray;
		&:first-child {
			border-top: 1px solid $m-gray;
		}
	}
	& th,
	& td.column-1 {
		text-align: left;
		padding: 1.25em 1.5em;
		@include f-w(700);
		background: $p-gray;
		@include media-breakpoint-up(lg) {
			width: 25%;
			min-width: 180px;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	& td {
		padding: 1.25em 1.5em;
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
}
/* table press CSS */
.tablepress td,
.tablepress th {
	padding: none;
}
.tablepress tbody td,
.tablepress tfoot th {
	border-top: none !important;
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
/* cotegory */
.category {
	&-item {
		display: inline-block;
		background-color: $dl-gray;
		color: $white;
		text-align: center;
		line-height: 1.4;
		padding: 0.4em 1.5em;
		font-size: 0.85em;
		@include l-sp(0.05em);
		@include radius(999px);
		@include f-w(500);
	}
}

/* txt */
.txt {
	& p {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

/* align */
.align {
	&-right {
		float: right;
		margin: 0 0 0.7rem 0.7rem;
	}
	&-left {
		float: left;
		margin: 0.7rem 0.7rem 0 0;
	}
	&-center {
		display: block;
		margin: 0 auto 0.7rem;
	}
}
