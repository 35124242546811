@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	loader
/--------------------------------------------------------------------*/

.loader {
	width: 100vw;
	height: 100vh;
	background: $bg_loader;
	z-index: z-index(loader);
	position: fixed;
	transform-origin: right;
	&.is-active {
		transform: scaleX(100);
		transform-origin: left;
	}
	&-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		justify-content: center;
		align-items: center;
		&.is-active {
			display: flex;
		}
	}
	&-filter {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: z-index(loader-inner);
		transform-origin: right;
		&.is-active {
			transform: scaleX(100);
			transform-origin: left;
		}
	}
}

/* spinner */
.spinner {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: $white;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

/* sk-chase */
.sk-chase {
	width: 48px;
	height: 48px;
	position: relative;
	animation: sk-chase 2.5s infinite linear both;
	&-wrap {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.sk-chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: sk-chase-dot 2s infinite ease-in-out both;
	&::before {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		background-color: $white;
		border-radius: 100%;
		animation: sk-chase-dot-before 2s infinite ease-in-out both;
	}
	@for $num from 1 through 6 {
		&:nth-child(#{$num}),
		&:nth-child(#{$num})::before {
			animation-delay: -1.2s + calc($num / 10);
		}
	}
}

@keyframes sk-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot {
	80%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot-before {
	50% {
		transform: scale(0.4);
	}
	100%,
	0% {
		transform: scale(1);
	}
}
