@charset "utf-8";
@use "base";
@use "_foundations/foundations";
/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
@use "_components/loader";  //ローダー
@use "_components/header";  //ヘッダー
@use "_components/nav";     //ナビゲーション
@use "_components/common-contents";//コンテンツ
@use "_components/common-parts";  //共通css
@use "_components/common-contact";  //共通CVエリア
@use "_components/common-banner";  //共通バナー
@use "_components/footer";  //フッター
@use "_components/column";  //カラム
@use "_components/sns";  //sns
@use "_components/pagination";  //ページネーション
@use "_plugins/lightbox"; //Light box

/* print css
----------------------------------------------------------------*/
@media print {
	header {display:none;}
	nav#global{display: none;}
	footer {display:none;}
}